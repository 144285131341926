import React from 'react' 
import { navigate } from 'gatsby'
import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";
import tw from 'tailwind.macro';
import styled from 'styled-components';
import {
  getAuthInfo,
  isAuthenticated,
  isBrowser,
  Logout
} from '../utils/auth.service';
// import { getUserInfo } from '../utils/user.service'; 

const AccountPage = () => {
  
  if (isBrowser && !isAuthenticated()) {
    navigate('/');
    return null;
  }

  // const userInfo = getUserInfo();
  const authInfo = getAuthInfo();
  
  const logout = () => {
    Logout(); 
  }

  const AccountDetails = () => {
    return (
      <div>
        <img src={authInfo.picture} alt="avatar" height="40px" style={{borderRadius: '50%', marginBottom: '1rem'}} /><br/>
        <strong> Username: </strong>{authInfo.nickname}<br/ >
        <strong>Email: </strong>{ authInfo.email} <br />
        
      </div>
    )
  }

  return ( 
    <Layout>
      <SEO title="Home" />
      <Container>
        <Header>
          <Title>Account details</Title>
        </Header>
        
        <AccountDetails />

        <Button onClick={logout}>Logout</Button>
      </Container>
    </Layout>
  )
}

export default AccountPage;

const Header = styled.header`${tw`mb-16 `}`
const Container = styled.section`${tw`max-w-lg m-auto py-16`}`
const Title = styled.h1`${tw`font-bold uppercase`}`
const Button = styled.button`${tw`bg-orange-500 rounded text-white my-8 p-4 uppercase border-0 cursor-pointer hover:bg-orange-600`}`